html,
body,
#root,
.App,
.page-container {
  width: 100%;
  height: 100%;
}

a.pi-link {
  text-decoration: underline !important;
}

/*New fixes for v4*/
.header-bar {
  margin-bottom: 0 !important;
  border: 0 !important;
}
.header-bar .asset-title::before {
  background: none !important;
}

.aside-assets-list {
  margin-top: 0 !important;
}

#viewer iframe {
  border: 0;
}

.main-front-assets-list {
  margin-top: 0 !important;
  max-height: 700px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.asset-item {
  font-size: 13px !important;
  line-height: 1.2em !important;
}
.aside-assets-list .ui.custom-sidebar {
  background-color: rgb(55, 55, 55) !important;
  overflow-y: auto !important;
  /*height: auto !important;*/
  max-height: none !important;
  padding-bottom: 200px !important;
}

@media only screen and (max-width: 767px) {
  .ui.items:not(.unstackable) > .item > .image > img {
    max-width: unset !important;
    max-height: unset !important;
    width: 30px !important;
  }

  .ui.items > .item > .content > .header:not(.ui) {
    font-size: 12px;
    text-align: center;
    max-width: 250px;
  }
  .ui.items > .item > .image + [class*="middle aligned"].content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }

  .ui.primary.button {
    width: 200px;
    margin-top: 5px;
  }
  .ui.items:last-child {
    padding-bottom: 100px;
  }
  .main-front-assets-list {
    max-height: 400px !important;
  }
}
